import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assests/images/logo.png";
import { CgSun } from "react-icons/cg/";
import { HiMoon } from "react-icons/hi";
import { useCallback, useState } from "react";
import { menuItems } from "./constants.js";
import "./Header.css";
import Lenguages from "../Lenguages/Lenguages.js";
import { HeaderContainer,HomeCTA, LogoContainer, MenuButton, NavContainer, NavMenu, ThemeToggle, } from "./Header.styles.js";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import { handleMenuClick } from "../../utils/handleNavigation.js";
import { translations } from "../../utils/translations.js";

const Header = ({ theme, setTheme, changeLanguage, language }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const { header } = translations[language];
  

  const handleChangeTheme = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log("Cambiando tema desde:", theme?.name);
      const newTheme = theme?.name === "light" ? "dark" : "light";
      setTheme(newTheme);
      localStorage.setItem("theme", newTheme);
    },
    [theme, setTheme]
  );

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleThemeToggleClick = (e) => {
    handleChangeTheme(e);
    closeMenu();
  };

  const handleLogoClick = () => {
    history.push("/home?splash=false")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <HeaderContainer>
      <NavContainer>
        <LogoContainer onClick={handleLogoClick}>
          <img className="logo-img" src={logo} alt="logo" />
          <span className="logo-name" style={{ color: theme.text }}> Ysolve </span>
        </LogoContainer>
        <MenuButton onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </MenuButton>

        <NavMenu isOpen={isMenuOpen}>
          {menuItems.map((item, index) => (
            <Link
              key={index}
              className="homei"
              style={{ borderRadius: 5, color: theme.text }}
              onClick={(e) => {
                handleMenuClick(history,e, item.id, pathname);
                closeMenu();
              }}
            >
              {header.links[item.id]}
            </Link>
          ))}
          <ThemeToggle
            onClick={handleThemeToggleClick}
            role="button"
            tabIndex={0}
          >
            {theme.name === "dark" ? (
              <HiMoon    
                strokeWidth={1}
                size={20}
                color={theme.name === "light" ? "#F9D784" : "#A7A7A7"}
              />
            ) : (
              <CgSun
                strokeWidth={1}
                size={20}
                color={theme.name === "light" ? "#F9D784" : "#A7A7A7"}
              />
            )}
          </ThemeToggle>
          <Lenguages changeLanguage={changeLanguage} theme={theme} />
          <HomeCTA
            onClick={() => {
              window.open("https://calendly.com/danielburru/ysolve", "_blank");
              closeMenu();
            }}
          >
            {header.cta}
          </HomeCTA>
        </NavMenu>
      </NavContainer>
    </HeaderContainer>
  );
};

export default Header;
