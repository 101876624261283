import styled, { createGlobalStyle } from "styled-components";

// Estilos globales optimizados con estilos de foco visibles
export const GlobalStyle = createGlobalStyle`
  body {
    line-height: 1.3;
    margin: 0;
    font-family: 'Roboto', sans-serif;
  }
  
  a:focus,
  button:focus {
    outline: 2px dashed #333;
    outline-offset: 2px;
  }
`;

// Botón "Saltar al contenido"
export const SkipLink = styled.a`
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  text-decoration: none;
  transition: top 0.3s ease;
  
  &:focus {
    top: 0;
  }
`;

// Contenedor principal con role "main" para identificar el contenido principal
export const Container = styled.main`
  position: relative;
  max-width: 900px;
  margin: 6rem auto 0 auto;
  padding: 2rem 1rem;
  text-align: center;
`;

// Encabezado
export const Header = styled.header`
  margin-bottom: 2rem;
`;

export const Title = styled.h1`
  font-size: 2rem;
  text-transform: capitalize;
`;

export const Section = styled.section`
  margin-bottom: 2rem;
  text-align: left;
`;

export const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: "•";
    position: absolute;
    left: 0;
    top: 0;
    color: #00c853; /* Verifica que este color tenga suficiente contraste con el fondo */
  }
`;