export const menuItems = [
  { id: "services" },
  { id: "projects" },
  { id: "contact" },
  { id: "blog" },
];

export const landingPages = [
  { label: "TeachLead as a service", id: "landing/cto-external" },
  { label: "Closed projects", id: "landing/closed-project" },
  { label: "Staff augmentation", id: "landing/staff-augmentation" },
];
