import React, { useState } from "react";
import {
  ContactContainer,
  SectionImage,
  ContainerImages,
  MainTitle,
} from "./Business.styles";
import { translations } from "../../utils/translations";
import gesinen from "../../assests/logos-business/gesinen.png";
import dival from "../../assests/logos-business/dival.png";
import upv from "../../assests/logos-business/upv.png";
import ocity from "../../assests/logos-business/ocity.png";
import foropromis from "../../assests/logos-business/promis.png";
import abogados from "../../assests/logos-business/abogados.png";
import swatid from "../../assests/logos-business/swatid.png";

const Business = ({ language, theme }) => {
  const { business } = translations[language];
  const logos = [
    { id: 1, name: "gesinen", logo: gesinen },
    { id: 2, name: "dival", logo: dival },
    { id: 3, name: "upv", logo: upv },
    { id: 5, name: "o-city", logo: ocity },
    { id: 6, name: "foropromis", logo: foropromis },
    { id: 7, name: "bsgabogados", logo: abogados },
    { id: 8, name: "swat-id", logo: swatid },
  ];

  return (
    <ContactContainer id="business">
      <MainTitle>{business.title}</MainTitle>
      <ContainerImages>
        {logos.map((item) => (
          <SectionImage key={item.id} src={item.logo} alt={item.name} />
        ))}
      </ContainerImages>
      <p>{business.subtitle}</p>
    </ContactContainer>
  );
};

export default Business;
