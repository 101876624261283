/* Change this file to get your personal Porfolio */
import image1 from "../assests/images/services/1.webp";
import image2 from "../assests/images/services/2.webp";
import image3 from "../assests/images/services/3.webp";
import image4 from "../assests/images/services/5.webp";
import image5 from "../assests/images/services/6.webp";
import image6 from "../assests/images/services/7.webp";
import image7 from "../assests/images/services/8.webp";
import image8 from "../assests/images/services/9.webp";

import image9 from "../assests/images/services/outsourcing/1.webp";
import image10 from "../assests/images/services/outsourcing/2.webp";
import image11 from "../assests/images/services/outsourcing/3.webp";

export const services = {
  es: [
    {
      id: "service-closed-project",
      title: "¿Cómo trabajamos proyectos llave en mano?",
      description:
        "Nos encargamos de todo el desarrollo de tu proyecto, desde la idea hasta la implementación final. Tú defines la visión, nosotros la hacemos realidad. 🚀",
      sections: [
        {
          title: "Definimos TÚ solución",
          description:
            "Se identifican los objetivos, el público objetivo, los requerimientos funcionales y no funcionales, y se define un roadmap general.",
          image: image1,
        },
        {
          title: "Investigación y análisis",
          description:
            "Investigamos el mercado, la competencia y se analizan las tecnologías disponibles para garantizar viabilidad técnica.",
          image: image2,
        },
        {
          title: "Diseño de UX/UI",
          description:
            "Diseñamos los wireframes y prototipos de la interfaz de usuario, optimizando la experiencia de usuario (UX).",
          image: image3,
        },
        {
          title: "Estructuramos la arquitectura del proyecto",
          description: "Creamos la arquitectura más adecuada para tú proyecto.",
          image: image4,
        },
        {
          title: "Desarrollo Frontend y Backend",
          description:
            "Hora de 'picar' código, nuestro equipo desarrolla el codigo siguiendo las indicaciones de arquitectura estudiadas previamente mediante metodologías agiles SCRUM.",
          image: image5,
        },
        {
          title: "Test QA ",
          description:
            "Nuestro equipo de QA realiza pruebas funcionales, de integración, rendimiento y usabilidad.",
          image: image6,
        },
        {
          title: "Deploy (Despliegue)",
          description:
            "Publicamos el proyecto y lo hacemos público para los usuarios finales.",
          image: image7,
        },
        {
          title: "Mantenimiento y actualizaciones",
          description:
            "Nos encargamos de que nada falle y actualizamos tu proyecto... quien mejor para ello?.",
          image: image8,
        },
      ],
      landing_link: "closed-project",
      cta_text: "¿Porqué contratar proyectos cerrados?",
    },
    {
      id: "service-staff-augmentation",
      title: "Staff Augmentation",
      description:
        "¿Necesitas talento tecnológico especializado para acelerar tu desarrollo? Incorpora a nuestros profesionales a tu equipo y mantén la agilidad sin comprometer la calidad.",
      sections: [
        {
          title: "🚀 Escala con talento experto",
          description:
            "Incorpora desarrolladores, arquitectos de software e ingenieros IoT con certificaciones y experiencia en proyectos de alto nivel. Cuenta también con nuestro equipo técnico de apoyo, no existe el fallo.",
          image: image9,
        },
        {
          title: "💰 Costos optimizados",
          description:
            "Tarifas competitivas y transparentes, sin costos ocultos ni gastos innecesarios, para maximizar tu inversión en talento tecnológico.",
          image: image10,
        },
        {
          title: "🤝 Integración sin fricción",
          description:
            "Nos adaptamos a tu metodología, herramientas y cultura de trabajo para garantizar una colaboración fluida y productiva desde el primer día.",
          image: image11,
        },
      ],
      landing_link: "staff-augmentation",
      cta_text: "¿Porqué contratar staff augmentation?",
    },
    {
      id: "cto-as-a-service",
      title: "TeachLead as a Service",
      description:
        "¿Necesitas una dirección tecnológica estratégica sin la carga de un CTO a tiempo completo? Nuestro servicio TeachLead as a Service te brinda el liderazgo experto que tu empresa necesita para crecer, innovar y mantenerse competitiva.",
      sections: [
        {
          title: "🚀 Visión y estrategia tecnológica",
          description:
            "Alineamos la tecnología con los objetivos de tu negocio, definiendo hojas de ruta, optimizando procesos y asegurando decisiones acertadas en arquitectura y desarrollo.",
          image: image5,
        },
        {
          title: "💰 Eficiencia y optimización de costos",
          description:
            "Accede a la experiencia de un CTO senior sin los costos fijos de una contratación permanente. Tarifas flexibles y transparentes que se ajustan a tus necesidades.",
          image: image10,
        },
        {
          title: "🤝 Integración sin fricción",
          description:
            "Nos convertimos en parte de tu equipo, adaptándonos a tu metodología, herramientas y cultura para una colaboración ágil y efectiva desde el primer día.",
          image: image11,
        },
      ],
      landing_link: "cto-external",
      cta_text: "¿Porqué contratar cto externo?",
    },
  ],
  en: [
    {
      id: "service-closed-project",
      title: "How Do We Handle Turnkey Projects?",
      description:
        "We take care of the entire development of your project, from the idea to the final implementation. You define the vision, and we make it a reality. 🚀",
      sections: [
        {
          title: "We Define Your Solution",
          description:
            "We identify the objectives, target audience, functional and non-functional requirements, and outline a general roadmap.",
          image: image1,
        },
        {
          title: "Research and Analysis",
          description:
            "We study the market and the competition, and analyze the available technologies to ensure technical feasibility.",
          image: image2,
        },
        {
          title: "UX/UI Design",
          description:
            "We design wireframes and prototypes for the user interface, optimizing the overall user experience (UX).",
          image: image3,
        },
        {
          title: "Structuring the Project’s Architecture",
          description:
            "We create the most suitable architecture for your project.",
          image: image4,
        },
        {
          title: "Frontend and Backend Development",
          description:
            "It’s time to code! Our team develops the code following the previously studied architecture guidelines using agile SCRUM methodologies.",
          image: image5,
        },
        {
          title: "QA Testing",
          description:
            "Our QA team performs functional, integration, performance, and usability tests.",
          image: image6,
        },
        {
          title: "Deploy",
          description:
            "We launch the project and make it available to end users.",
          image: image7,
        },
        {
          title: "Maintenance and Updates",
          description:
            "We ensure everything runs smoothly and keep your project updated... who better to do that?",
          image: image8,
        },
      ],
      landing_link: "closed-project",
      cta_text: "Why Hire Turnkey Projects?",
    },
    {
      id: "service-staff-augmentation",
      title: "Staff Augmentation",
      description:
        "Do you need specialized tech talent to accelerate your development? Incorporate our professionals into your team and maintain agility without compromising quality.",
      sections: [
        {
          title: "🚀 Scale with Expert Talent",
          description:
            "Incorporate developers, software architects, and IoT engineers with certifications and experience in high-level projects. Plus, count on our support technical team—errors are not an option.",
          image: image9,
        },
        {
          title: "💰 Optimized Costs",
          description:
            "Competitive and transparent rates with no hidden fees or unnecessary expenses, designed to maximize your investment in tech talent.",
          image: image10,
        },
        {
          title: "🤝 Seamless Integration",
          description:
            "We adapt to your methodology, tools, and work culture to ensure smooth and productive collaboration from day one.",
          image: image11,
        },
      ],
      landing_link: "staff-augmentation",
      cta_text: "Why Hire Staff Augmentation?",
    },
    {
      id: "cto-as-a-service",
      title: "TechLead as a Service",
      description:
        "Do you need strategic technological leadership without the burden of a full-time CTO? Our TechLead as a Service provides the expert leadership your company needs to grow, innovate, and remain competitive.",
      sections: [
        {
          title: "🚀 Technological Vision and Strategy",
          description:
            "We align technology with your business objectives by defining roadmaps, optimizing processes, and ensuring sound decisions in architecture and development.",
          image: image5,
        },
        {
          title: "💰 Efficiency and Cost Optimization",
          description:
            "Gain access to the experience of a senior CTO without the fixed costs of a permanent hire. Flexible and transparent rates that adjust to your needs.",
          image: image10,
        },
        {
          title: "🤝 Seamless Integration",
          description:
            "We become part of your team, adapting to your methodology, tools, and culture for agile and effective collaboration from day one.",
          image: image11,
        },
      ],
      landing_link: "cto-external",
      cta_text: "Why Hire an External CTO?",
    },
  ],
};

export const misionVisionValores = [
  {
    // img: require("./assests/images/undraw_online_transactions_02ka.png"),
    title: "Misión",
    subtitle: "Soluciones tecnológicas innovadoras",
    text:
      "En Ysolve, nuestra misión es transformar desafíos en soluciones tecnológicas innovadoras. Trabajamos incansablemente para brindar a nuestros clientes la capacidad de superar obstáculos y alcanzar sus objetivos a través del desarrollo de software, hardware y diseño industrial. Nos comprometemos a adaptarnos a las necesidades únicas de cada proyecto, combinando creatividad y experiencia para generar impacto positivo en diversos sectores.",
  },
  {
    // img: require("./assests/images/website.png"),
    title: "Visión",
    subtitle: "Soluciones tecnológicas personalizadas",
    text:
      "Nuestra visión es ser líderes reconocidos en el ámbito de soluciones tecnológicas personalizadas. Aspiramos a ser la primera elección para aquellos que buscan resolver problemas complejos y aprovechar al máximo el potencial de la tecnología. Visualizamos un futuro en el que nuestras soluciones impulsen la innovación en múltiples industrias, mejorando la vida de las personas y contribuyendo al progreso global.",
  },
  {
    // img: require("./assests/images/app.png"),
    title: "Valores",
    subtitle: "Innovación, Compromiso, Calidad y Responsabilidad",
    text: `Estamos completamente comprometidos con los proyectos y metas de nuestros clientes, priorizando su éxito. Adaptabilidad es nuestra fortaleza: nos ajustamos a los cambios en los proyectos y sectores, permitiéndonos abordar cualquier desafío y brindar soluciones adecuadas. La excelencia es nuestra norma, ya que como expertos en tecnología, perseguimos la calidad en cada faceta de nuestro trabajo. Operamos con integridad y transparencia, manteniendo relaciones comprometidas y claras con nuestros clientes y socios.`,
  },
];
