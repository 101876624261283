import React from "react";
import "./Footer.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
/* eslint-disable jsx-a11y/accessible-emoji */
import { translations } from "../../utils/translations";
import { landingPages, menuItems } from "../header/constants.js";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { handleMenuClick } from "../../utils/handleNavigation.js";

export default function Footer({ language }) {
  const { footer, header } = translations[language];
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <img
          className="logo-img-footer"
          alt="logo"
          src={require("../../assests/images/logo.png")}
        />

        <p className="footer-links">
          <a
            onClick={(e) => handleMenuClick(history, e, "home", pathname)}
          >{` ${"Home"} `}</a>
          {menuItems.map((menuItem) => (
            <a
              onClick={(e) =>
                handleMenuClick(history, e, menuItem.id, pathname)
              }
            >{` ${header.links[menuItem.id]} `}</a>
          ))}
          {landingPages.map((landingPage) => (
            <a
              onClick={(e) =>
                handleMenuClick(history, e, landingPage.id, pathname)
              }
            >{` ${landingPage.label} `}</a>
          ))}
        </p>

        <p className="footer-company-name">Ysolve © 2025</p>
      </div>

      <div className="footer-center">
        <div>
          <i className="fa fa-map-marker"></i>
          <p>
            <span> Avenida Hermanos Machado 13, 204</span> Valencia, España
          </p>
        </div>

        <div>
          <a href="https://wa.me/+34627669080" target={"_blank"}>
            <i className="fa fa-phone"></i>
            <p>+34 627 669 080 </p>
          </a>
        </div>

        <div>
          <i className="fa fa-envelope"></i>
          <p>
            <a href="mailto:info@ysolve.es">danielburruchaga@ysolve.es</a>
          </p>
        </div>
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <span>{footer.title}</span>
          {footer.aboutus}
        </p>

        <div className="footer-icons">
          <a
            href=" https://www.linkedin.com/company/79045707/admin/feed/posts/"
            target={"_blank"}
          >
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <strong>{"linkedin"}</strong>
                </Tooltip>
              }
            >
              <li
                style={{
                  listStyle: "none",
                  marginLeft: "0.75rem",
                  fontSize: "20px",
                }}
                name={"linkedin"}
              >
                <span
                  className="iconify"
                  data-icon={"simple-icons:linkedin"}
                  data-inline="false"
                ></span>
              </li>
            </OverlayTrigger>
          </a>
        </div>
      </div>
    </footer>
  );
}
