import React from "react";
import "./Projects.css";
import styled from "styled-components";

const CTA = styled.button`
  
  margin: 2rem 0 !important ;
  text-transform: uppercase;
  margin: 10px;
  transition: all 0.2s ease-in-out;
  padding: 0.5rem 1rem;
  cursor: pointer;
  box-shadow: 0 2px 10px #dd68b2;
  background-color: #ee2aa6;
  color: #ffffff !important;
  border-radius: 5px;
  &:hover {
    transform: translateY(-2px);
  }
`;


export const ProjectSection = ({
  title,
  descriptions,
  services,
  buttonText,
  isActive,
  image,
  theme,
  buttonLink
}) => {

  return (
    <div className={`servicios-verticales-wrapper ${isActive ? "active" : ""}`}>
      <div className="content-section">
        <h1 className="title"  style={{ textTransform: "uppercase",fontWeight:1 }}>
          {title}
        </h1>
        {descriptions.map((desc, index) => (
          <p key={index} className="description">
            {desc}
          </p>
        ))}
        <div className="tags-container">
          {services.map((service, index) => (
            <span
              key={index}
              className="tag"
              style={{
                boxShadow:
                  theme.name !== "dark"
                    ? "0 2px 8px rgba(0, 0, 0, 0.1)"
                    : "0 2px 8px rgba(255, 255, 255, 0.1)",
              }}
            >
              {service}
            </span>
          ))}
        </div>
        
        <CTA
          className="button"
          style={{
            boxShadow:
              theme.name !== "dark"
                ? "0 2px 8px rgba(0, 0, 0, 0.1)"
                : "0 2px 8px rgba(255, 255, 255, 0.1)",
          }}
          onClick={() => buttonLink ?? window.open(buttonLink, "_blank", "noopener,noreferrer")}
        >
          {buttonText}
        </CTA>
      </div>
      <div className="image-section">
        <img src={image} alt={title} />
      </div>
    </div>
  );
};
