import { Helmet } from "react-helmet-async";

const structuredSEOHome = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Ysolve",
  url: "https://www.ysolve.es",
  logo: "https://ysolve.es/static/media/logo.4c696cbe37494f8e55f2.png",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "+34 627 669 080",
    contactType: "customer service",
    email: "danielburruchaga@ysolve.es",
  },
  address: {
    "@type": "PostalAddress",
    streetAddress: "Avenida Hermanos Machado 13, 204",
    addressLocality: "Valencia",
    addressCountry: "ES",
  },
  description:
    "Soluciones inteligentes para un mundo conectado. Desarrollamos tu solución a medida abarcando proyectos cerrados, Staff Augmentation, TechLead As a Service, plataformas web, soluciones digitales, innovación tecnológica y multiplataformas conectadas.",
  service: [
    {
      "@type": "Service",
      name: "Desarrollo de proyectos cerrados",
      description:
        "Creamos soluciones de software a la medida, desde la conceptualización hasta la entrega final. Garantía de calidad, plazos cerrados, metodologías ágiles y soporte post-lanzamiento.",
    },
    {
      "@type": "Service",
      name: "Staff Augmentation",
      description:
        "Incorpora a nuestro talento tecnológico a tu equipo para escalar rápido y mantener la agilidad. Talento certificado, rápida disponibilidad y tarifas competitivas.",
    },
    {
      "@type": "Service",
      name: "TechLead As a Service",
      description:
        "Ofrecemos el liderazgo tecnológico que tu empresa necesita para crecer y escalar sin complicaciones.",
    },
    {
      "@type": "Service",
      name: "Plataformas web",
      description:
        "Desarrollamos plataformas digitales de alto nivel especializadas en productos, servicios y contenidos que facilitan la relación entre usuarios. Diseño y programación a medida para ofrecer un producto digital único.",
    },
    {
      "@type": "Service",
      name: "Soluciones digitales",
      description:
        "Creamos soluciones tecnológicas innovadoras que transforman la manera en que las empresas interactúan con sus usuarios y gestionan sus procesos.",
    },
    {
      "@type": "Service",
      name: "Innovación tecnológica",
      description:
        "Implementamos las últimas tecnologías y metodologías para ofrecer experiencias únicas y efectivas en el mercado digital.",
    },
    {
      "@type": "Service",
      name: "Multiplataformas Conectadas",
      description:
        "Desarrollamos soluciones digitales unificadas que operan en web, apps móviles y sistemas embebidos, garantizando una integración eficiente entre frontend, backend y bases de datos.",
    },
  ],
};

export const SEOHome = () => {
  return (
    <>
      <Helmet>
        <title>Ysolve - Soluciones inteligentes para un mundo conectado</title>
        <meta
          name="description"
          content="Desarrollamos soluciones a medida: proyectos cerrados, Staff Augmentation, TechLead As a Service, plataformas web, soluciones digitales, innovación tecnológica y multiplataformas conectadas. Transforma tu negocio con Ysolve."
        />
        <meta
          name="keywords"
          content="Ysolve, soluciones inteligentes, desarrollo de software, proyectos cerrados, staff augmentation, techlead, plataformas web, soluciones digitales, innovación tecnológica, multiplataformas conectadas, IoT, apps, e-commerce, streaming, blockchain, cloud computing"
        />
        <link rel="canonical" href="https://www.ysolve.es/" />
        <meta
          property="og:title"
          content="Ysolve - Soluciones inteligentes para un mundo conectado"
        />
        <meta
          property="og:description"
          content="Desarrollamos soluciones a medida: proyectos cerrados, Staff Augmentation, TechLead As a Service, plataformas web, soluciones digitales, innovación tecnológica y multiplataformas conectadas."
        />
        <meta property="og:url" content="https://www.ysolve.es/" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {JSON.stringify(structuredSEOHome)}
        </script>
      </Helmet>
      <header>
        {/* Aquí puedes incluir el logo, navegación o cualquier elemento del header */}
      </header>
    </>
  );
};
