import styled from "styled-components";

export const ContactContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 4rem 1rem;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  @media (max-width: 768px) {
    padding: 2rem 0.5rem;
    gap: 2rem;
  }
`;

export const MainTitle = styled.h1`
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.text};
  letter-spacing: 2px;
  line-height: 1.2;
  word-spacing: 4px;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 4px;
    height: auto;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
