import styled from "styled-components";

export const VideoContainer = styled.div`
  width: 100%;
  height: 42rem;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;

export const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  z-index: 10;
  width: 90%; // Añadido para controlar el ancho en móvil
  max-width: 1200px; // Añadido para limitar el ancho máximo
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MainTitle = styled.h1`
  font-family: Raleway, sans-serif;
  font-size: 43px;
  font-style: normal;
  font-weight: 200;
  letter-spacing: 8.7px;
  line-height: 55.9px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 20px;
    letter-spacing: 4px;
    height: auto; // Eliminar altura fija
  }

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Subheader = styled.h2`
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto; // Cambiar altura fija a auto
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 10px 0;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 20px;
`;

export const StyledButton = styled.button`
  background-color: ${(props) =>
    props.isHovered ? "rgb(80 50 50 / .65)" : "rgb(0 0 0 / .75)"};
  border-color: rgb(141, 141, 141);
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  color: rgb(229, 229, 229);
  cursor: pointer;
  fill: rgb(229, 229, 229);
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 500;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 49px;
  letter-spacing: 2px;
  transition: 0.3s;
  display: inline-block;
  width: 20rem;
`;
