import React, { useState } from "react";
import {
  //CallButton,
  //WhatsappButton,
  Checkbox,
  CheckboxContainer,
  ContactContainer,
  ContactForm,
  ContactSection,
  Description,
  FormRow,
  Input,
  MainTitle,
  SubmitButton,
  ButtonContainer,
  //TextArea,
  //Title,
  StyledButton,
} from "./Contact.styles";
import { translations } from "../../utils/translations";

const Contact = ({ language, theme }) => {
  const { contact } = translations[language];
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
    privacy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <ContactContainer id="contact">
      <ContactSection>
        <MainTitle>{contact.left.header}</MainTitle>
        <Description>{contact.left.description}</Description>
        <ButtonContainer>
          <StyledButton href="tel:627669080">
            {" "}
            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
            {contact.left.button}{" "}
          </StyledButton>
          <StyledButton
            href="https://wa.me/627669080"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp" aria-hidden="true"></i> WHATSAPP
          </StyledButton>
        </ButtonContainer>
      </ContactSection>
      <ContactSection>
        <ContactForm onSubmit={handleSubmit} noValidate>
          <FormRow>
            <label htmlFor="name">{contact.form.nombre}</label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              aria-required="true"
            />
          </FormRow>
          <FormRow>
            <label htmlFor="email">{contact.form.email}</label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              aria-required="true"
            />
          </FormRow>
          <FormRow>
            <label htmlFor="phone">{contact.form.telefono}</label>
            <Input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <label htmlFor="company">{contact.form.empresa}</label>
            <Input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
          </FormRow>
          {/*<FormRow>
            <label htmlFor="message">{contact.form.help}</label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              aria-required="true"
            />
          </FormRow>*/}
          <CheckboxContainer>
            <Checkbox
              type="checkbox"
              id="privacy"
              name="privacy"
              checked={formData.privacy}
              onChange={handleChange}
              required
              aria-required="true"
            />
            <label htmlFor="privacy">{contact.form.policity}</label>
          </CheckboxContainer>
          <SubmitButton type="submit" disabled={!formData.privacy}>
            {contact.form.button}
          </SubmitButton>
        </ContactForm>
      </ContactSection>
    </ContactContainer>
  );
};

export default Contact;
