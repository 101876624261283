export const ctoLandingText = {
  es: {
    seo: {
      title: "CTO Externo: Liderazgo Tecnológico Sin Costos Fijos",
      metaDescription: "",
      keywords:
        "CTO Externo, liderazgo tecnológico, dirección tecnológica, sin costos fijos, Ysolve, desarrollo de software",
      openGraph: {
        title: "",
        description: "",
        image: "",
      },
      breadcrumb: {},
      schema: {},
      url: "https://ysolve.es/cto-externo",
      baseUrl: "https://ysolve.es",
    },

    title: "🚀 CTO Externo: Liderazgo Tecnológico Sin Costos Fijos",
    description: `
        ¿Necesitas un Director de Tecnología (CTO) con experiencia pero sin
        los costos de una contratación permanente? Con nuestro servicio de
        CTO Externo, te ofrecemos el liderazgo tecnológico que tu empresa
        necesita para crecer y escalar sin complicaciones.`,
    sections: [
      {
        title: "🏆 ¿Por qué contratar un CTO Externo?",
        items: {
          type: "list",
          content: [
            "✅ Estrategia tecnológica alineada con tu negocio.",
            "✅ Optimización y escalabilidad de tu infraestructura IT.",
            "✅ Gestión y liderazgo de equipos de desarrollo.",
            "✅ Selección de las mejores tecnologías y herramientas.",
            "✅ Mayor seguridad y eficiencia en tus procesos tecnológicos.",
          ],
        },
      },
      {
        title: "📈 Ideal para:",
        items: {
          type: "list",
          content: [
            "✅ Startups y empresas en crecimiento que necesitan expertise técnica sin costos fijos elevados.",
            "✅ Negocios sin un equipo técnico interno que requieren asesoría estratégica.",
            "✅ Empresas que buscan escalar su tecnología de manera rápida y efectiva.",
          ],
        },
      },
      {
        title: "🔥 ¡Transforma tu tecnología en una ventaja competitiva!",
        items: {
          type: "text",
          content: [
            "Con nuestra experiencia en desarrollo, IoT, infraestructura y  software, te ayudamos a llevar tu negocio al siguiente nivel sin la necesidad de contratar un CTO a tiempo completo.",
            "📩 <strong>Contáctanos hoy</strong> y descubre cómo podemos impulsar tu empresa con un CTO Externo.",
          ],
        },
      },
    ],
    cta: "¿Hablamos de tu proyecto?",
  },
  en: {
    seo: {
      title: "External CTO: Technological Leadership Without Fixed Costs",
      metaDescription: "",
      keywords:
        "External CTO, technological leadership, tech direction, no fixed costs, Ysolve, software development",
      openGraph: {
        title: "",
        description: "",
        image: "",
      },
      breadcrumb: {},
      schema: {},
      url: "https://ysolve.es/cto-externo",
      baseUrl: "https://ysolve.es",
    },

    title: "🚀 External CTO: Technological Leadership Without Fixed Costs",
    description: `
          Do you need a Chief Technology Officer (CTO) with experience but without
          the costs of a permanent hire? With our External CTO service, we offer you the technological leadership that your company
          needs to grow and scale without complications.`,
    sections: [
      {
        title: "🏆 Why Hire an External CTO?",
        items: {
          type: "list",
          content: [
            "✅ Technology strategy aligned with your business.",
            "✅ Optimization and scalability of your IT infrastructure.",
            "✅ Management and leadership of development teams.",
            "✅ Selection of the best technologies and tools.",
            "✅ Increased security and efficiency in your tech processes.",
          ],
        },
      },
      {
        title: "📈 Ideal for:",
        items: {
          type: "list",
          content: [
            "✅ Startups and growing companies that need technical expertise without high fixed costs.",
            "✅ Businesses without an internal tech team that require strategic advice.",
            "✅ Companies looking to scale their technology quickly and effectively.",
          ],
        },
      },
      {
        title: "🔥 Transform Your Technology into a Competitive Advantage!",
        items: {
          type: "text",
          content: [
            "With our expertise in development, IoT, infrastructure, and software, we help you take your business to the next level without the need to hire a full-time CTO.",
            "📩 <strong>Contact us today</strong> and discover how we can boost your company with an External CTO.",
          ],
        },
      },
    ],
    cta: "Ready to Talk About Your Project?",
  },
};
