import React, { useEffect } from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet-async";
import { CTAButton } from "../../components/ctaButton/ctaButton";
import {
  GlobalStyle,
  List,
  ListItem,
  Section,
  Text,
  Subtitle,
  Container,
  SkipLink,
  Header,
  Title,
} from "./landing.jsx";

const CtoLandingPage = ({ texts, language }) => {
  const metaTitle = `${texts.seo.title} | Ysolve Soluciones Tecnológicas`;
  const metaDescription = texts.seo.metaDescription;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }}>
        {/* Meta tags SEO */}
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={texts.seo.keywords} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={texts.seo.url} />

        {/* Open Graph para redes sociales */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content="https://ysolve.es/cto-externo.jpg" />
        <meta property="og:url" content={texts.seo.url} />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Breadcrumbs Schema */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Inicio",
                item: texts.seo.baseUrl,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: texts.title,
                item: texts.seo.url,
              },
            ],
          })}
        </script>

        {/* Schema para el servicio */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            name: texts.title,
            description: metaDescription,
            provider: {
              "@type": "Organization",
              name: "Ysolve",
              url: texts.seo.baseUrl,
            },
          })}
        </script>
      </Helmet>
      <GlobalStyle />

      {/* Enlace para saltar al contenido */}
      <SkipLink href="#main-content">Saltar al contenido</SkipLink>

      <Container id="main-content">
        <Header>
          <Title>{texts.title}</Title>
        </Header>

        <Section id="title">
          <Text>{parse(DOMPurify.sanitize(texts.description))}</Text>
        </Section>

        {texts.sections.map((section, idx) => (
          <Section key={idx}>
            <Subtitle>{section.title}</Subtitle>
            {section.items.type === "list" ? (
              <List>
                {section.items.content.map((item, index) => (
                  <ListItem key={index}>
                    {parse(DOMPurify.sanitize(item))}
                  </ListItem>
                ))}
              </List>
            ) : (
              <>
                {section.items.content.map((content, index) => {
                  const cleanHTML = DOMPurify.sanitize(content);
                  return <Text key={index}>{parse(cleanHTML)}</Text>;
                })}
              </>
            )}
          </Section>
        ))}

        <CTAButton
          href="mailto:danielburruchaga@ysolve.es"
          title="Contáctanos para más información"
          aria-label="Contáctanos para más información"
        >
          {texts.cta}
        </CTAButton>
      </Container>
    </>
  );
};

export default CtoLandingPage;
