import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import ServiceDetail from "./ServiceDetail/ServiceDetail";
import Header from "../components/header/Header";
import detectLanguage from "../utils/languaje";
import LandingPage from "../pages/landing/landing";
import { ctoLandingText } from "../data/landing/cto-landing/cto-landing";
import { closedProjectsText } from "../data/landing/closed-project/closed-projects";
import Footer from "../components/footer/Footer";
import { staffAugmentationLandingText } from "../data/landing/staff-augmentation/staff-augmentation";
import Splash from "../pages/splash/Splash";

const Main = (propss) => {
  const [language, setLanguage] = useState(detectLanguage());
  const [isSplash, setIsSplash] = useState(true);
  const changeLanguage = (lang) => setLanguage(lang);

  // Componente para la ruta 404
  const NotFound = () => (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      <h1 style={{ padding: "2rem" }}>404 - Page Not Found</h1>
      <p>The page you're looking for does not exist.</p>
    </div>
  );
  useEffect(() => {
    changeLanguage(language);
  }, []);

  // Con BrowserRouter, location.search siempre estará presente, por lo que este chequeo es más simple
  const checkIfSplash = (props) => {
    const queryParams = new URLSearchParams(props.location.search);
    const splashParam = queryParams.get("splash");
    if (splashParam === "true") {
      setIsSplash(false);
    }
  };

  return (
    <Router basename="/">
      {!isSplash && (
        <Header
          theme={propss.theme}
          setTheme={propss.setTheme}
          changeLanguage={changeLanguage}
          language={language}
        />
      )}
      <Switch>
        <Route
          path="/"
          exact
          render={() => <Splash setSplash={setIsSplash} theme={propss.theme} />}
        />
        <Route
          path="/home"
          render={(props) => {
            checkIfSplash(props);
            return (
              <Home
                {...props}
                setSplash={setIsSplash}
                theme={propss.theme}
                setTheme={propss.setTheme}
                language={language}
              />
            );
          }}
        />
        <Route
          path="/service/:id"
          render={(props) => {
            setIsSplash(false);
            return (
              <ServiceDetail
                {...props}
                theme={propss.theme}
                setTheme={propss.setTheme}
                language={language}
              />
            );
          }}
        />
        <Route
          path="/landing/closed-project"
          render={(props) => {
            setIsSplash(false);
            return (
              <LandingPage
                texts={closedProjectsText[language]}
                language={language}
              />
            );
          }}
        />
        <Route
          path="/landing/cto-external"
          render={(props) => {
            setIsSplash(false);
            return (
              <LandingPage
                texts={ctoLandingText[language]}
                language={language}
              />
            );
          }}
        />
        <Route
          path="/landing/staff-augmentation"
          render={(props) => {
            setIsSplash(false);
            return (
              <LandingPage
                texts={staffAugmentationLandingText[language]}
                language={language}
              />
            );
          }}
        />
        <Route component={NotFound} />
      </Switch>
      {!isSplash && <Footer language={language} />}
    </Router>
  );
};

export default Main;
