import React, { useEffect } from "react";
import { Video } from "../../components/video";
import { Projects, ProjectsContainer } from "../../containers/projects";
import { HomeContainer, ContentWrapper } from "./Home.styles";
import Contact from "../../containers/contact/Contact";
import { ServiciosVerticales } from "../../containers/servicices-verticales/ServiciosVerticales";
import { SEOHome } from "../../seo/SEOhome";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Business from "../../containers/business/Business";
import Note from "../../containers/note/Note";

function Home(props) {
  const params = useParams();

  console.log(params.isLoading);
  useEffect(() => {
    if (params) props.setSplash(false);
  }, []);

  return (
    <HomeContainer id="home" style={{ width: "100%", height: "100%" }}>
      <SEOHome />
      <Video language={props.language} />
      <ContentWrapper>
        <ServiciosVerticales language={props.language} />
        {/* <Note language={props.language} theme={props.theme} />*/}
        <ProjectsContainer>
          <Projects theme={props.theme} language={props.language} />
        </ProjectsContainer>
        <Business language={props.language} theme={props.theme} />
        <Contact language={props.language} theme={props.theme} />
      </ContentWrapper>
    </HomeContainer>
  );
}

export default Home;
