import React,{useEffect} from "react";

import { useHistory } from "react-router-dom";
import { ServiceContainer, Section, TextContent, ImageContent, TitleContent, SectionImage, BackButton } from "./ServiceDetail.styles";
import { CTAButton } from "../../components/ctaButton/ctaButton"
import { services } from "../../data/services";
import { translations } from "../../utils/translations";

const ServiceDetail = ({ theme, match, language }) => {
  const id = match.params.id;
  const service = services[language].find((s) => s.id === id);
  const history = useHistory();

  useEffect(()=>{ window.scrollTo({ top: 0, behavior: "smooth" }) },[])
  
  if (!service) return <div>Servicio no encontrado</div>;

  return (
    <>
    <ServiceContainer id="title" >
      <BackButton onClick={() => history.push("/home")}> {translations[language].common.back} </BackButton>
      <TitleContent style={{ color: theme.text, lineHeight: 1.6 }}>
        <h1 >{service.title}</h1>
        <p style={{ padding: "1rem 0" }}>{service.description}</p>
      </TitleContent>
      {service.sections.map((section, index) => (
        <Section key={index} reverse={index % 2 !== 0}>
          <TextContent style={{ color: theme.text }}>
            <h2>{section.title}</h2>
            <p>{section.description}</p>
          </TextContent>
          <ImageContent>
            <SectionImage src={section.image} alt={section.title} />
          </ImageContent>
        </Section>
      ))}

    </ServiceContainer>
    <div style={{ display: "flex", flexFlow: "column", alignItems: "center", justifyContent: "center", }}>  
      <CTAButton onClick={() => {history.push(`/landing/${service.landing_link}`); }} title="Contáctanos para más información">
        {service.cta_text}?
      </CTAButton>
    </div>
  </>
  );
};

export default ServiceDetail;
