export const staffAugmentationLandingText = {
  es: {
    seo: {
      title:
        "Staff Augmentation: El talento que necesitas, justo cuando lo necesitas",
      metaDescription:
        "¿Tu equipo de desarrollo está al límite? Descubre cómo Staff Augmentation te ofrece agilidad, integración instantánea y resultados sin los costos de contratación tradicionales.",
      keywords:
        "Staff Augmentation, talento IT, desarrollo de software, outsourcing, equipos ágiles, integración, tecnología",
      openGraph: {
        title:
          "Staff Augmentation: El talento que necesitas, justo cuando lo necesitas",
        description:
          "Refuerza tu equipo con especialistas sin la burocracia de la contratación tradicional.",
        image: "",
      },
      breadcrumb: {},
      schema: {},
      url: "https://ysolve.es/staff-augmentation",
      baseUrl: "https://ysolve.es",
    },

    title:
      "🚀 Staff Augmentation: El talento que necesitas, justo cuando lo necesitas",
    description: `
      ¿Tu equipo de desarrollo está al límite? Proyectos retrasados, sobrecarga de trabajo y la falta de especialistas pueden detener tu crecimiento. 
      Con nuestro servicio de Staff Augmentation, integra el talento que necesitas de forma ágil y sin los costos fijos de la contratación tradicional.
    `,
    sections: [
      {
        title: "🚨 ¿Tu equipo de desarrollo está al límite?",
        items: {
          type: "text",
          content: [
            "Proyectos retrasados, sobrecarga de trabajo y falta de especialistas pueden frenar tu crecimiento. La contratación tradicional es costosa y lenta, y no siempre encuentras el talento adecuado.",
          ],
        },
      },
      {
        title: "💡 ¿Por qué elegir Staff Augmentation?",
        items: {
          type: "list",
          content: [
            "🚀 Agilidad sin límites: Refuerza tu equipo con desarrolladores, arquitectos de software e ingenieros IoT altamente capacitados.",
            "💰 Menos costos, más resultados: Paga solo por el talento que realmente necesitas, con tarifas competitivas y sin costos ocultos.",
            "🤝 Integración instantánea: Colabora sin fricción desde el primer día, adaptándonos a tu metodología, herramientas y cultura.",
            "⏳ Reduce el riesgo y acelera el éxito: Evita retrasos y problemas por falta de recursos, cumpliendo plazos sin comprometer la calidad.",
          ],
        },
      },
      {
        title: "🔗 ¡Impulsa tu crecimiento!",
        items: {
          type: "text",
          content: [
            "No dejes que la falta de talento detenga tu crecimiento. Contáctanos hoy mismo para descubrir cómo podemos reforzar tu equipo y llevar tus proyectos al siguiente nivel.",
          ],
        },
      },
    ],
    cta: "¿Hablamos de tu proyecto?",
  },
  en: {
    seo: {
      title: "Staff Augmentation: The Talent You Need, Right When You Need It",
      metaDescription:
        "Is your development team at its limit? Discover how Staff Augmentation offers agility, instant integration, and results without the traditional hiring costs.",
      keywords:
        "Staff Augmentation, IT talent, software development, outsourcing, agile teams, integration, technology",
      openGraph: {
        title:
          "Staff Augmentation: The Talent You Need, Right When You Need It",
        description:
          "Strengthen your team with specialists without the bureaucracy of traditional hiring.",
        image: "",
      },
      breadcrumb: {},
      schema: {},
      url: "https://ysolve.es/staff-augmentation",
      baseUrl: "https://ysolve.es",
    },

    title: "🚀 Staff Augmentation: The Talent You Need, Right When You Need It",
    description: `
        Is your development team at its limit? Delayed projects, work overload, and a lack of specialists can hinder your growth. 
        With our Staff Augmentation service, integrate the talent you need in an agile way and without the fixed costs of traditional hiring.
      `,
    sections: [
      {
        title: "🚨 Is Your Development Team at Its Limit?",
        items: {
          type: "text",
          content: [
            "Delayed projects, work overload, and a lack of specialists can slow down your growth. Traditional hiring is expensive and slow, and you don’t always find the right talent.",
          ],
        },
      },
      {
        title: "💡 Why Choose Staff Augmentation?",
        items: {
          type: "list",
          content: [
            "🚀 Unlimited Agility: Strengthen your team with highly skilled developers, software architects, and IoT engineers.",
            "💰 Less Cost, More Results: Pay only for the talent you truly need, with competitive rates and no hidden fees.",
            "🤝 Instant Integration: Collaborate seamlessly from day one, adapting to your methodology, tools, and culture.",
            "⏳ Reduce Risk and Accelerate Success: Avoid delays and resource shortages, meeting deadlines without compromising quality.",
          ],
        },
      },
      {
        title: "🔗 Boost Your Growth!",
        items: {
          type: "text",
          content: [
            "Don't let the lack of talent stop your growth. Contact us today to discover how we can strengthen your team and take your projects to the next level.",
          ],
        },
      },
    ],
    cta: "Ready to Talk About Your Project?",
  },
};
