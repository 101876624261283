export const closedProjectsText = {
  es: {
    seo: {
      title: "Contratar desarrollo de proyecto cerrado.",
      metaDescription: "",
      keywords:
        "CTO Externo, liderazgo tecnológico, dirección tecnológica, sin costos fijos, Ysolve, desarrollo de software",
      openGraph: {
        title: "",
        description: "",
        image: "",
      },
      breadcrumb: {},
      schema: {},
      url: "https://ysolve.es/cto-externo",
      baseUrl: "https://ysolve.es",
    },
    title: "🚀 ¿Contratar desarrollo de proyectos externos?",
    description: `
  Sabemos lo que es tener una gran idea, pero chocarte con problemas como:
  <div style="padding:10px">
    <p>
      ❌ No saber qué tecnologías usar.
    </p>
    <p>
    ❌ Perder tiempo buscando desarrolladores y coordinando equipos.
    </p>
    <p>
      ❌ Los costos ocultos de infraestructura y mantenimiento.
    </p>
    <p>
      ❌ Retrasos interminables en el desarrollo.
    </p>
  </div>
  
  Con Ysolve, olvídate de <strong>TODO </strong> eso. Te entregamos un proyecto llave en mano: listo para usar, optimizado y sin complicaciones.
    `,
    sections: [
      {
        title: "🔑 ¿Cómo lo hacemos?",
        items: {
          type: "list",
          content: [
            "✅ Desarrollo Full Stack → Webs y apps escalables y de alto rendimiento.",
            "✅ Experiencia impecable para tus usuarios → Interfaces rápidas, atractivas y fáciles de usar.",
            "✅ Backend sólido y seguro → APIs eficientes que hacen que todo fluya sin problemas.",
            "✅ DevOps y automatización → Infraestructura optimizada y sin sorpresas técnicas.",
            "✅ Deploy sin dolores de cabeza → Tu plataforma lista para funcionar sin fallos.",
            "✅ Mantenimiento y soporte → No te dejamos solo; nos aseguramos de que todo siga perfecto.",
          ],
        },
      },
      {
        title: "🌍 Usamos las mejores tecnologías para ti",
        items: {
          type: "list",
          content: [
            "✅ Frontend → React, Vue, Angular, Tailwind, Next.js",
            "✅ Backend → Node.js, Typescrypt, Python",
            "✅ Bases de Datos → PostgreSQL, MySQL, MongoDB, Firebase",
            "✅ DevOps → Docker, Kubernetes, CI/CD, AWS, Azure, GCP",
            "✅ Infraestructura → VPS, servidores dedicados, contenedores y cloud computing",
          ],
        },
      },
      {
        title: "🎯 Este servicio es para ti si...",
        items: {
          type: "list",
          content: [
            "💡 Tienes una startup y necesitas un producto funcional desde el día uno.",
            "🏢 Eres una empresa en crecimiento y quieres escalar sin miedo a problemas técnicos.",
            "📲 Quieres digitalizar tu negocio con una plataforma web o app eficiente.",
            "🛠️ Necesitas software a medida para optimizar tus procesos y ahorrar tiempo.",
          ],
        },
      },
      {
        title:
          "🔥 Tú encárgate de hacer crecer tu negocio. Nosotros nos ocupamos del resto.",
        items: {
          type: "text",
          content: ["📩 Hablemos y pongamos en marcha tu proyecto hoy mismo."],
        },
      },
    ],
    cta: "¿Hablamos de tu proyecto?",
  },
  en: {
    seo: {
      title: "Hire Turnkey Project Development.",
      metaDescription: "",
      keywords:
        "External CTO, technological leadership, tech direction, no fixed costs, Ysolve, software development",
      openGraph: {
        title: "",
        description: "",
        image: "",
      },
      breadcrumb: {},
      schema: {},
      url: "https://ysolve.es/cto-externo",
      baseUrl: "https://ysolve.es",
    },
    title: "🚀 Hire External Project Development?",
    description: `
    We know what it’s like to have a great idea, but run into issues like:
    <div style="padding:10px">
      <p>
        ❌ Not knowing which technologies to use.
      </p>
      <p>
        ❌ Wasting time searching for developers and coordinating teams.
      </p>
      <p>
        ❌ Hidden costs of infrastructure and maintenance.
      </p>
      <p>
        ❌ Endless delays in development.
      </p>
    </div>
    
    With Ysolve, forget <strong>ALL</strong> that. We deliver a turnkey project: ready to use, optimized, and hassle-free.
    `,
    sections: [
      {
        title: "🔑 How Do We Do It?",
        items: {
          type: "list",
          content: [
            "✅ Full Stack Development → Scalable, high-performance websites and apps.",
            "✅ Impeccable User Experience → Fast, attractive, and user-friendly interfaces.",
            "✅ Solid and Secure Backend → Efficient APIs that ensure everything runs smoothly.",
            "✅ DevOps and Automation → Optimized infrastructure with no technical surprises.",
            "✅ Headache-Free Deployment → Your platform, ready to run flawlessly.",
            "✅ Maintenance and Support → We don’t leave you on your own; we ensure everything stays perfect.",
          ],
        },
      },
      {
        title: "🌍 We Use the Best Technologies for You",
        items: {
          type: "list",
          content: [
            "✅ Frontend → React, Vue, Angular, Tailwind, Next.js",
            "✅ Backend → Node.js, Typescript, Python",
            "✅ Databases → PostgreSQL, MySQL, MongoDB, Firebase",
            "✅ DevOps → Docker, Kubernetes, CI/CD, AWS, Azure, GCP",
            "✅ Infrastructure → VPS, dedicated servers, containers, and cloud computing",
          ],
        },
      },
      {
        title: "🎯 This Service Is For You If...",
        items: {
          type: "list",
          content: [
            "💡 You have a startup and need a functional product from day one.",
            "🏢 You are a growing company and want to scale without worrying about technical issues.",
            "📲 You want to digitalize your business with an efficient web platform or app.",
            "🛠️ You need custom software to optimize your processes and save time.",
          ],
        },
      },
      {
        title:
          "🔥 You Focus on Growing Your Business. We Take Care of the Rest.",
        items: {
          type: "text",
          content: ["📩 Let's talk and kickstart your project today."],
        },
      },
    ],
    cta: "Ready to Talk About Your Project?",
  },
};
