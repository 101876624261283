import styled from "styled-components";

export const ContactContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 4rem 1rem;
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  @media (max-width: 768px) {
    padding: 2rem 0.5rem;
    gap: 2rem;
  }
`;

export const ContainerImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centra las imágenes en móviles */
  gap: 2rem; /* Añade un espacio uniforme entre imágenes */
`;

export const MainTitle = styled.h1`
  text-transform: uppercase;
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.text};
  letter-spacing: 2px;
  line-height: 1.2;
  word-spacing: 4px;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 4px;
    height: auto;
  }

  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const SectionImage = styled.img`
  border-radius: 10px;
  width: 8rem;
  height: 4rem;
  object-fit: cover;
  filter: grayscale(60%);
  margin: 0 3rem;

  @media (max-width: 768px) {
    width: 8rem;
    height: 4rem;
    margin: 0 1rem;
  }

  @media (max-width: 480px) {
    width: 7rem;
    height: 3.5rem;
    margin: 0 0.5rem;
  }
`;
