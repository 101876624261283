import imageA from "../../assests/images/ocity.png";
import imageB from "../../assests/images/Ges.png";
import imageC from "../../assests/images/foropromis.png";
import imageD from "../../assests/images/Doki.png";
import { translations } from "../../utils/translations";

export const getSections = (language = "es") => {
  const t = translations[language].projects;

  return [
    {
      ...t.platformsSection,
      image: imageA,
      buttonLink: "https://o-city.org"
    },
    {
      ...t.solutionsSection,
      image: imageC,
      buttonLink: "https://foropromis.org"
    },
    {
      ...t.innovationSection,
      image: imageB,
      buttonLink: "https://lora.dival.es"
    },
    {
      ...t.multiplatformSection,
      image: imageD,
    },
  ];
};
