import styled from "styled-components";

export const HeaderContainer = styled.header`
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: ${({ theme }) => theme.body};
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  .logo-img {
    height: 40px;
    width: auto;
  }

  .logo-name {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

export const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  gap: 2rem;
  list-style: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    width: 70%;
    height: 100vh;
    background-color: ${({ theme }) => theme.body};
    padding: 6rem 2rem;
    transition: right 0.3s ease-in-out;
    box-shadow: ${({ isOpen }) =>
      isOpen ? "-5px 0 15px rgba(0,0,0,0.1)" : "none"};

    li {
      width: 100%;
      text-align: center;
      padding: 1rem 0;
      border-bottom: 1px solid ${({ theme }) => theme.text}22;

      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const HomeCTA = styled.li`
  text-transform: uppercase;
  margin: 10px;
  transition: all 0.2s ease-in-out;
  padding: 0.5rem 1rem;
  cursor: pointer;
  box-shadow: 0 2px 10px #dd68b2;
  background-color: #ee2aa6;
  color: #ffffff !important;
  border-radius: 5px;
  &:hover {
    transform: translateY(-2px);
  }
`;

export const Overlay = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
`;

export const ThemeToggle = styled.li`
  text-align: center;
  align-content: center;
  cursor: pointer;
  height: 45px;
  width: 45px;
  margin-right: 5px;
  margin-left: 15px;
  padding-top: 5px;
  margin-top: 5px;
  border-radius: 50%;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    theme.name === "light" ? "#7CD1F7" : "#292C3F"};
  outline: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 3px 8px
      ${({ theme }) => (theme.name === "light" ? "#F7D774" : "#646464")};
  }

  @media (max-width: 768px) {
    width: 45px !important;
    margin: 10px auto;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    && {
      width: 45px;
      padding: 5px 0;
      border-bottom: none;
    }
  }
`;
