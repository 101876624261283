import React, { useEffect, useRef, useState } from "react";
import videoLanding from "../../assests/ysolve.mov";
import { translations } from "../../utils/translations";
import {
  VideoContainer,
  VideoElement,
  ContentWrapper,
  FlexContainer,
  MainTitle,
  Subheader,
  ButtonContainer,
  StyledButton,
} from "./Video.styles";
const Video = ({ language }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { video } = translations[language];
  const videoRef = useRef(null); // Añadir referencia al video

  // Función para intentar reproducir el video
  const attemptPlay = () => {
    videoRef.current.play().catch((error) => {
      console.log("Reproducción automática bloqueada:", error);
      // Opcional: Mostrar un botón de play o una imagen de fallback
    });
  };

  useEffect(() => {
    // Intentar reproducir cuando el componente se monte
    attemptPlay();

    // Evento para intentar reproducir cuando el usuario interactúa con la página
    const handleInteraction = () => {
      attemptPlay();
      // Remover el listener después de la primera interacción
      document.removeEventListener("touchstart", handleInteraction);
    };

    document.addEventListener("touchstart", handleInteraction);

    return () => {
      document.removeEventListener("touchstart", handleInteraction);
    };
  }, []);
  return (
    <VideoContainer id="video-container">
      <VideoElement ref={videoRef}  src={videoLanding} type="video/mp4" autoPlay 
        playsInline muted loop poster="/path/to/poster-image.jpg" />
      <ContentWrapper>
        <FlexContainer>
          <MainTitle>{video.main}</MainTitle>
          <Subheader>{video.subheader}</Subheader>
          <ButtonContainer>
            <StyledButton
              onClick={() =>
                window.open("https://calendly.com/danielburru/ysolve", "_blank")
              }
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              isHovered={isHovered}
            >
              {video.button}
            </StyledButton>
          </ButtonContainer>
        </FlexContainer>
      </ContentWrapper>
    </VideoContainer>
  );
};

export { Video };
