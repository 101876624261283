import React, { useState, useEffect } from "react";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { themes } from "./theme";
import { GlobalStyles } from "./global";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    console.log("HOLA", process.env.REACT_APP_GOOGLE_TRACKING_ID);
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
    // Para enviar una vista de página:
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  return (
    <div>
      <ThemeProvider theme={themes[theme]}>
        <GlobalStyles />
        <Main theme={themes[theme]} setTheme={setTheme} />
      </ThemeProvider>
    </div>
  );
}

export default App;
