export const handleMenuClick = (history, event, goTo, pathname) => {
  event.preventDefault();
  if (goTo.includes("/")) {
    history.push(`/${goTo}`);
    return;
  }

  if (pathname.startsWith("/service/") || pathname.startsWith("/landing")) {
    history.push("/home");
    setTimeout(() => {
      const element = document.getElementById(goTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  } else {
    const element = document.getElementById(goTo);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
